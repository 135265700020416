module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-anchor-links/gatsby-browser.js'),
      options: {"plugins":[],"offset":-80,"duration":2000},
    },{
      plugin: require('../node_modules/gatsby-plugin-typography/gatsby-browser.js'),
      options: {"plugins":[],"pathToConfigModule":"src/utils/typography"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Andys Web Design","short_name":"AndyWeb","start_url":"/","display":"minimal-ui","icon":"src/images/phoenix.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"1945e4c9d452af9d74a0f45df6460a5d"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
